import PropTypes from 'prop-types'
import React from 'react'
import Resume from '../images/resume.png'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import dreamV from '../images/The_Dream_-_IV_Play_[Music_Video].mp4'
import dream from '../images/The_Dream_-_IV_Play_[Music_Video]-261.jpg'
import JimmyReel from '../images/JIMMY_2014-004.jpg'
import JimmyReelV from '../images/jIMMY_2014.mp4'
import medicalreel from '../images/medicalreel_jm.jpg'
import medicalreelV from '../images/medicalreel_jm.mp4'
import history from '../images/History_Channel_3D.jpg'
import historyV from '../images/History_Channel_3D.mp4'
import Elefun from '../images/Elefun_and_Friends.jpg'
import ElefunV from '../images/Elefun_and_Friends.mp4'
import firstdata from '../images/First_Data_Graphics_Reel.jpg'
import firstdataV from '../images/First_Data_Graphics_Reel.mp4'
import lovesigns from '../images/Love_Signs_-_GFX.jpg'
import lovesignsV from '../images/Love_Signs_-_GFX.mp4'
import Kreo from '../images/Kreo_Battleship_Mini_Movie.jpg'
import KreoV from '../images/Kreo_Battleship_Mini_Movie.mp4'
import coke from '../images/Coke_Pay_It_Forward.jpg'
import cokeV from '../images/Coke_Pay_It_Forward.mp4'
import nerf from '../images/NERF_Light_it_Up.jpg'
import nerfV from '../images/NERF_Light_it_Up.mp4'
import verizon from '../images/NetSup_Verizon.jpg'
import verizonV from '../images/NetSup_Verizon.mp4'
import ION from '../images/ION4.jpg'
import IONV from '../images/ION4.mp4'
import NEWBRAND from '../images/Verizon_New_Brand_Module_Excerpt.jpg'
import NEWBRANDV from '../images/Verizon_New_Brand_Module_Excerpt.mp4'
import Ambitious from '../images/SO_AMBITIOUS.jpg'
import AmbitiousV from '../images/SO_AMBITIOUS.mp4'
import HBO from '../images/Comcast_HBO_30.jpg'
import HBOV from '../images/Comcast_HBO_30.mp4'
import Ghost from '../images/ghostrobot_logo.png'
import Whiskey from '../images/Whiskeytree_logo.png'
import Refinery from '../images/Refinery_logo1.png'
import Phosphene from '../images/phos_logo.png'
import EastCoast from '../images/ecd_logo.png'
import '../assets/scss/main.scss'


class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Creative Work</h2>
          <p>A Sample of projects worked on.</p>
          <table border="2">
          <tbody>
          <tr>
          <td>
          <div>
            <a href={JimmyReelV}>
            <img src={JimmyReel} width="100%"></img></a>
          </div>
          </td>
          <td>
          <div>
            <a href={medicalreelV}>
            <img src={medicalreel} width="100%"></img></a>
          </div>
          </td>
          <td>
          <div>
            <a href={dreamV}>
            <img src={dream} width="100%"></img></a>
          </div>
          </td>
          </tr>
          <td>
          <div><h4 align="center">Commercial Reel</h4></div>
          </td>
          <td>
          <div><h4 align="center">Medical Reel</h4></div>
          </td>
          <td>
          <div><h4 align="center">The Dream IV Play</h4></div>
          </td>
          <tr>      
          <td>
          <div>
            <a href={historyV}>
            <img src={history} width="100%"></img></a>
          </div>
          </td>
          <td>
          <div>
            <a href={ElefunV}>
            <img src={Elefun} width="100%"></img></a>
          </div>
          </td>
          <td>
          <div>
            <a href={firstdataV}>
            <img src={firstdata} width="100%"></img></a>
          </div>
          </td>
          </tr>
          <tr>
          <td>
           <div><h4 align="center">History Channel</h4></div>
          </td>
          <td>
            <div><h4 align="center">Elefun and Friends</h4></div>
          </td>
          <td>
            <div><h4 align="center">First Data</h4></div>
          </td>
          </tr>
          <tr>
          <td>
          <div>
            <a href={lovesignsV}>
            <img src={lovesigns} width="100%"></img></a>
          </div>
          </td>
          <td>
          <div>
            <a href={KreoV}>
            <img src={Kreo} width="100%"></img></a>
          </div>
          </td>
          <td>
          <div>
            <a href={cokeV}>
            <img src={coke} width="100%"></img></a>
          </div>
          </td>
          </tr>
          <tr>
          <td>
            <div><h4 align="center">Love Signs</h4></div>
          </td>
          <td>
            <div><h4 align="center">Kreo BattleShip</h4></div>
          </td>
          <td>
            <div><h4 align="center">Coke Pay It Forward</h4></div>
          </td>
          </tr>
          <tr>
          <td>
          <div>
            <a href={nerfV}>
            <img src={nerf} width="100%"></img></a>
          </div>
          </td>
          <td>
          <div>
            <a href={verizonV}>
            <img src={verizon} width="100%"></img></a>
          </div>
          </td>
          <td>
          <div>
            <a href={IONV}>
            <img src={ION} width="100%"></img></a>
          </div>
          </td>
          </tr>
          <tr>
          <td>
            <div><h4 align="center">Nerf Light It Up</h4></div>
          </td>
          <td>
            <div><h4 align="center">Net Verizon</h4></div>
          </td>
          <td>
            <div><h4 align="center">ION 4</h4></div>
          </td>
          </tr>
          <tr>
          <td>
          <div>
            <a href={NEWBRANDV}>
            <img src={NEWBRAND} width="100%"></img></a>
          </div>
          </td>
          <td>
          <div>
            <a href={AmbitiousV}>
            <img src={Ambitious} width="100%"></img></a>
          </div>
          </td>
          <td>
          <div>
            <a href={HBOV}>
           <img src={HBO} width="100%"></img></a>
          </div>
          </td>
          </tr>
          <tr>
          <td>
            <div><h4 align="center">Verizon Brand Module</h4></div>
          </td>
          <td>
            <div><h4 align="center">So Ambitious</h4></div>
          </td>
          <td>
            <div><h4 align="center">Comcast HBO</h4></div>
          </td>
          </tr>
          </tbody>
          </table>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'resume' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">resume</h2>
          <span className="image main">
            <img src={Resume} alt="" />
          </span>
          <p>
            For an in depth breakdown of duties, responsibilities, and accomplishments please head over to my 
            <a href = "https://www.linkedin.com/in/jimmymarrero/"> LinkedIn page. </a>
          </p>
          
          
          <h2>Experience</h2>
          <img src={Phosphene}width="105" height="25"></img>
          <h3>Director of Information Technology</h3>
          <h4>Phosphene</h4>
          <h4>March 2016—Present</h4>
          <p>
          </p>
          <img src={EastCoast}width="90" height="50"></img>
          <h3>IT Manager/Technical Director</h3>
          <h4>EastCoastDigital</h4>
          <h4>Janurary 2015—March 2016</h4>
          <p>
          </p>
          <img src={Refinery}width="65" height="65"></img>
          <h3>Creative/Technical Director</h3>
          <h4>RefineryNYC</h4>
          <h4>December 2009—March 2015</h4>
          <p>
          </p>
          
          <img src={Whiskey}width="50" height="50"></img>
          <h3>Technical Director</h3>
          <h4>Whiskytree</h4>
          <h4>November 2012—Februrary 2013</h4>
          <p>
          </p>
          <img src={Ghost}width="50" height="50"></img>
          <h3>Senior 3D Animator</h3>
          <h4>Ghost Robot</h4>
          <h4>2011</h4>
          <p>
          </p>
          <h2>Education</h2>
          <h3>Bachelor of Fine Arts (B.F.A.), Computer Animation/Interactive Media</h3>
          <h5>Fashion Institute of Tech, State University of New York, New York, NY (2003-2005)</h5>
          <p>
          </p>
          <h3>Certification, MCSE/Network+/A+</h3>
          <h4>Berkley College, New York, NY (2001-2002)</h4>
          <p>
          </p>
          <h3>Certification, Internet Technologies</h3>
          <h4>New York University, New York, NY (2000-2001)</h4>
          <p>
          </p>
          <h3>Associates of Applied Sciences (A.A.S.), Illustration</h3>
          <h4>Fashion Institute of Tech, State University of New York, New York, NY (1998-2000)</h4>
          <p>
          </p>
          
          
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
       
          <p>
            Lorem ipsum dolor sit amet, consectetur et adipiscing elit. Praesent
            eleifend dignissim arcu, at eleifend sapien imperdiet ac. Aliquam
            erat volutpat. Praesent urna nisi, fringila lorem et vehicula
            lacinia quam. Integer sollicitudin mauris nec lorem luctus ultrices.
            Aliquam libero et malesuada fames ac ante ipsum primis in faucibus.
            Cras viverra ligula sit amet ex mollis mattis lorem ipsum dolor sit
            amet.
          </p>
          {close}
        </article>


        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true">
           <input type="hidden" name="bot-field"/>    
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
