import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
  <div className="logo">
      <span className="icon fa-cog"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>JIMMY MARRERO</h1>
        <h2>Director of IT/Creative Technologist</h2>
        <p>
        Creative, dedicated and talented professional with over 10 years of experience <br />
        maintaining and integrating new technologies into all aspects of the visual effects industry.{' '}
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('resume')
            }}
          >
            Resume
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              window.location = ('https://linkedin.com/in/jimmymarrero')
            }}
          >
            LinkedIn
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              window.location = ('https://github.com/jimmarrero')
            }}
          >
            Github
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
